import React from 'react';
import NextLink from 'next/link';
import { Box, Button } from '@material-ui/core';

const notFoundPage = () => (
  <>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <h1 color="primary">404 - Page Not Found</h1>
      <Button color="secondary" variant="contained" size="large">
        <NextLink href="/" prefetch={false} passHref>
          <a>Go back home</a>
        </NextLink>
      </Button>
    </Box>
  </>
);

export default notFoundPage;
